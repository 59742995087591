import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import RSSParser from "rss-parser"
import Layout from "../components/layout"
import HeaderImage from "../images/podcast-header-image.jpeg"

import EmailIcon from "../images/email30x30.jpeg"
import WebsiteIcon from "../images/website30x30.jpeg"

import TwitterIcon from "../images/twitter30x30.jpeg"
import FacebookIcon from "../images/facebook30x30.jpeg"
import InstagramIcon from "../images/instagram30x30.jpeg"
import PinterestIcon from "../images/pinterest30x30.jpeg"
import LinkedInIcon from "../images/linkedin30x30.jpeg"
import TumblrIcon from "../images/tumblr30x30.jpeg"
import YouTubeIcon from "../images/youtube30x30.jpeg"
import TwitchIcon from "../images/twitch30x30.jpeg"

import AppleIcon from "../images/apple-podcasts.png"
import GoogleIcon from "../images/google-podcasts.png"
import SpotifyIcon from "../images/spotify.png"
import StitcherIcon from "../images/stitcher.png"
import TuneInIcon from "../images/tunein.png"
import iHeartIcon from "../images/iheart.png"
import PandoraIcon from "../images/pandora.png"
import RssIcon from "../images/rss.png"
import SEO from "../components/seo"

const Heading = styled.h2`
  font-family: "Orbitron", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  border-bottom: 3px solid red;
  margin: 2rem 0;

  max-width: 250px;
  font-weight: normal;
`

const Subscription = styled.div`
  max-width: 360px;
`

const ContactIcons = styled.div`
  display: flex;

  img {
    margin-right: 1rem;
  }
`

const SocialIcons = styled.div`
  max-width: 350px;

  display: flex;
  flex-wrap: wrap;

  img {
    margin-right: 0.8rem;
  }
`

const PodcastPageLayout = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 1.5rem;

  .about {
    @media (max-width: 900px) {
      display: none;
    }
  }

  .about-mobile {
    display: none;
    @media (max-width: 900px) {
      display: block;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 100%;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 1;
  background-color: rgba(28, 117, 188, 0.8);
  width: 100%;
  height: 270px;
  /* z-index: 2; */
`

const StyledHeaderImage = styled.div`
  position: relative;
  width: 100%;
  height: 270px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-content {
    position: absolute;
    height: 250px;
    display: flex;

    @media (max-width: 700px) {
      flex-wrap: wrap;
    }

    left: 100px;
    bottom: 50%;
    margin-bottom: -155px;
    color: white;
    align-items: center;

    @media (max-width: 700px) {
      left: 50%;
      top: 20px;
      margin-left: -100px;
    }

    .album-art-container {
      width: 170px;
      height: 170px;
      @media (max-width: 700px) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  #content-text {
    margin-left: 1.5em;
    h1 {
      font-size: 2.5em;
    }
    h2 {
      font-size: 1.5em;
      @media (max-width: 700px) {
        font-size: 1.5em;
      }
    }

    @media (max-width: 700px) {
      width: 350px;
      text-align: center;

      left: 50%;
      margin-left: -75px;
    }
  }
`

const PodcastEpsiodes = styled.div`
  .container {
    width: 90%;
    h1 {
      font-size: 1em;
    }
    p {
      font-size: 0.9em;
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    contentfulPodcast(slug: { eq: $slug }) {
      title
      slug
      author
      email
      feedUrl
      description
      category
      websiteUrl
      twitterUrl
      pintristUrl
      facebookUrl
      instagramUrl
      pandoraUrl
      iheartUrl
      linkedinUrl
      youTubeUrl
      tuneinradioUrl
      applePodcastUrl
      googlePodcastUrl
      spotifyUrl
      stitcherUrl
      pocketCastUrl
      overcastUrl
      twitchUrl
      tumblerUrl
      liveShow
      albumArt {
        file {
          url
        }
      }
    }
  }
`
// feed passed in dynamically through props
//props.data.contentfulPodcast.feedUrl
//https://cors-anywhere.herokuapp.com/
//https://geek-life-radio-proxy.herokuapp.com/
//https://geek-life-feed-proxy.herokuapp.com/fetch/

const PodcastTemplate = props => {
  const CORS_PROXY = "https://geek-life-feed-proxy.herokuapp.com/fetch/"
  const FEED_URL = props.data.contentfulPodcast.feedUrl
  const [feed, setFeed] = useState({ title: "", items: [] })

  const handleFeed = async () => {
    let response = await fetch(FEED_URL)
    response.json().then(response => setFeed(response))

    // let parser = new RSSParser()
    // try {
    //   const feed = await parser.parseURL(`${FEED_URL}`)
    //   console.log(feed)
    //   setFeed(feed)
    // } catch (error) {
    //   console.log(error)
    // }
  }

  // const handleRssFeed = () => {
  //   const rssData = async () => {
  //     let parser = new RSSParser()

  //     const feed = await parser.parseURL(`${CORS_PROXY}${FEED_URL}`)
  //     setFeed(feed)
  //   }
  // fetch(`${FEED_URL}`)
  //   .then(response => response.text())
  //   .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
  //   .then(data => console.log(`Here is the Data ${data}`))

  //props.data.contentfulPodcast.feedUrl

  useEffect(() => {
    // rssData()
    handleFeed()
  }, [])

  return (
    <>
      <SEO
        title={props.data.contentfulPodcast.title}
        description={props.data.contentfulPodcast.description}
      />
      <Layout>
        <StyledHeaderImage>
          <Overlay />
          <img
            src={props.data.contentfulPodcast.albumArt.file.url}
            alt="Podcast Album"
          />
          <div className="header-content">
            <div className="album-art-container">
              <img
                src={props.data.contentfulPodcast.albumArt.file.url}
                alt=""
              />
            </div>
            <div id="content-text">
              <h1>{props.data.contentfulPodcast.title}</h1>
              <h2>{props.data.contentfulPodcast.author}</h2>
            </div>
          </div>
        </StyledHeaderImage>
        <PodcastPageLayout>
          <div className="left-column">
            <div className="about-mobile">
              <Heading>About</Heading>
              <p style={{ fontSize: "1.2rem" }}>
                {props.data.contentfulPodcast.description}
              </p>
            </div>
            <Heading>Past Episodes</Heading>
            <PodcastEpsiodes>
              {feed.items &&
                feed.items.slice(0, 5).map((item, i) => (
                  <div className="container">
                    <h1>{item.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: item.content }} />
                    <audio controls>
                      <source src={item.enclosure.link} />
                    </audio>
                    <hr />
                  </div>
                ))}
            </PodcastEpsiodes>
          </div>
          <div className="right-column">
            <div className="about">
              <Heading>About</Heading>
              <p style={{ fontSize: "1.2rem" }}>
                {props.data.contentfulPodcast.description}
              </p>
            </div>
            <Heading>Contact</Heading>
            <ContactIcons>
              {props.data.contentfulPodcast.email && (
                <a href={`mailto:${props.data.contentfulPodcast.email}`}>
                  <img src={EmailIcon} alt="email" title="email" />
                </a>
              )}
              {props.data.contentfulPodcast.websiteUrl && (
                <a
                  href={props.data.contentfulPodcast.websiteUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={WebsiteIcon} alt="website" title="website" />
                </a>
              )}
            </ContactIcons>
            <Heading>Social</Heading>
            <SocialIcons>
              {props.data.contentfulPodcast.twitterUrl && (
                <a
                  href={props.data.contentfulPodcast.twitterUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitterIcon} alt="website" title="Twitter" />
                </a>
              )}

              {props.data.contentfulPodcast.facebookUrl && (
                <a
                  href={props.data.contentfulPodcast.facebookUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FacebookIcon} alt="website" title="Facebook" />
                </a>
              )}
              {props.data.contentfulPodcast.instagramUrl && (
                <a
                  href={props.data.contentfulPodcast.instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={InstagramIcon} alt="website" title="Instagram" />
                </a>
              )}

              {props.data.contentfulPodcast.pinterestUrl && (
                <a
                  href={props.data.contentfulPodcast.pinterestUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PinterestIcon} alt="website" title="Pinterest" />
                </a>
              )}

              {props.data.contentfulPodcast.tumblerUrl && (
                <a
                  href={props.data.contentfulPodcast.tumblerUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TumblrIcon} alt="website" title="Tumblr" />
                </a>
              )}

              {props.data.contentfulPodcast.linkedinUrl && (
                <a
                  href={props.data.contentfulPodcast.linkedinUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedInIcon} alt="website" title="LinkedIn" />
                </a>
              )}
              {props.data.contentfulPodcast.youTubeUrl && (
                <a
                  href={props.data.contentfulPodcast.youTubeUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={YouTubeIcon} alt="website" title="YouTube" />
                </a>
              )}

              {props.data.contentfulPodcast.twitchUrl && (
                <a
                  href={props.data.contentfulPodcast.twitchUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TwitchIcon} alt="website" title="Twitch" />
                </a>
              )}
            </SocialIcons>
            <Heading>Subscribe</Heading>
            <Subscription>
              {props.data.contentfulPodcast.applePodcastUrl && (
                <a
                  href={props.data.contentfulPodcast.applePodcastUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={AppleIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.googlePodcastUrl && (
                <a
                  href={props.data.contentfulPodcast.googlePodcastUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={GoogleIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.spotifyUrl && (
                <a
                  href={props.data.contentfulPodcast.spotifyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={SpotifyIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.stitcherUrl && (
                <a
                  href={props.data.contentfulPodcast.stitcherUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={StitcherIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.tuneinradioUrl && (
                <a
                  href={props.data.contentfulPodcast.tuneinradioUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={TuneInIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.pandoraUrl && (
                <a
                  href={props.data.contentfulPodcast.pandoraUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={PandoraIcon} alt="subscribe with apple podcast" />
                </a>
              )}
              {props.data.contentfulPodcast.iheartUrl && (
                <a
                  href={props.data.contentfulPodcast.iheartUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={iHeartIcon} alt="subscribe with apple podcast" />
                </a>
              )}
            </Subscription>
          </div>
        </PodcastPageLayout>
      </Layout>
    </>
  )
}

export default PodcastTemplate
